<template>
  <div class="slogan-container flex-row-top wei-container" v-if="introduce.list">
    <div
      class="slogan-item flex1"
      v-for="(item, index) in introduce.list"
      :key="index"
    >
      <img
        :src="require('../../assets/images/intro' + (index + 1) + '.png')"
        alt=""
      />
      <div class="name">{{ item.name }}</div>
      <div class="info">{{ item.info }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mission",
  components: {},
  data() {
    return {
      introduce: {},
    };
  },
  computed: {},
  created() {
    this.introduce = this.$t("introduce");
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.slogan-container {
  text-align: left;
  margin-top: 10px;
  background-color: #fff;
  padding: 80px 70px 90px 70px;
  .name {
    font-size: 18px;
    font-weight: bold;
    color: #19222f;
    margin: 30px 0 20px 0;
  }
  .info {
    font-size: 14px;
    color: #aaaaaa;
    line-height: 1.5;
  }
}
</style>
