<template>
  <div class="banner">
    <div class="title">
      <div class="square"></div>
      <div class="cname">{{ title.cname }}</div>
      <div class="sname">{{ title.sname }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: Object,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height:352px;
  background:url('../../assets/images/banner.jpg') no-repeat;
  background-position: bottom;
  .title {
    overflow: hidden;
    position: relative;
    width: auto;
    display: inline-block;
    margin-top:116px;
    .cname {
      font-size: 24px;
      margin-bottom: 24px;
      font-size: 48px;
      font-family: myFamily;
      color: #ffffff;
      position: relative;
      z-index: 2;
      letter-spacing: 6px;
      line-height: 1;
    }

    .sname {
      font-size: 18px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      text-transform:uppercase;
    }
    .square {
      width: 208px;
      height: 12px;
      background: #41cfad;
      position: absolute;
      width: 100%;
      top: 36px;
      left: 0;
    }
  }
}
</style>
