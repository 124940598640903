<template lang="">
  <div class="aboutUs">
    <Banner :title="aboutUs"></Banner>
    <Title :title="aboutUs"></Title>
    <div class="company_banner wei-container">
       <i class="el-icon-arrow-left pointer" @click="prev"></i>
     <i class="el-icon-arrow-right pointer" @click="next"></i>
      <el-carousel ref="carousel" trigger="click" height="560px" arrow="never" loop>
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="company_container wei-container">
      <div class="name">{{ companyInfo.dbdz }}</div>
      <div class="info">{{ companyInfo.gsjs }}</div>
    </div>
    <div class="flex-row-top img_container">
      <img src="../../assets/images/com_pic1.jpg" />
      <img src="../../assets/images/com_pic2.jpg" />
      <img src="../../assets/images/com_pic3.jpg" />
      <img src="../../assets/images/com_pic4.jpg" />
    </div>
    <Mission></Mission>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import Title from "@/components/title";
import Mission from "@/components/Mission";
import Banner from "@/components/Banner";
export default {
  components: {
    Mission,
    Banner,
    Title,
  },
  data() {
    return {
      aboutUs: {},
      bannerList: [
        require("../../assets/images/com_img1.jpg"),
        require("../../assets/images/com_pic1.jpg"),
        require("../../assets/images/com_pic4.jpg"),
      ],
      activeBanner: 0,
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  created() {
    this.aboutUs = this.$t("aboutUs");
  },
  methods: {
    changeBanner(index) {
    },
    setActiveItem() {},
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>
<style lang="scss">
.aboutUs {
  background-color: #fff;
  padding-bottom: 100px;
  .company_banner {
    max-width: 992px;
    width:80%;
    margin: 0 auto;
    position: relative;
  }
  .company_container {
    margin-top: 80px;
    margin-bottom: 160px;
    position: relative;
    .name {
      font-size: 18px;
      margin-bottom: 40px;
    }
    .info {
      line-height: 1.5;
    }
  }
  .img_container {
    img {
      flex: 1;
      width: 25%;
    }
  }
}
</style>
<style lang="scss">
.aboutUs {
  .c-title {
    .cname {
      margin-top: 100px;
    }
  }
  .el-carousel__item {
    width: 992px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .el-carousel__arrow {
    background-color: transparent;
  }
  .el-icon-arrow-left,
  .el-icon-arrow-right {
    color: #19222f;
    font-size: 30px;
    position: absolute;
    top: 50%;
  }
  .el-icon-arrow-left {
    left: -90px;
  }
  .el-icon-arrow-right {
    right: -90px;
  }
}
</style>
