<template>
  <div class="c-title">
    <div class="cname">{{title.cname}}</div>
    <div class="sname">{{title.sname}}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: Object,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.c-title {
  overflow: hidden;
  text-align: center;
  .cname {
    margin-top: 150px;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .sname {
    font-size: 14px;
    color: #aaaaaa;
    margin-bottom: 60px;
     text-transform:uppercase;
  }
}
</style>

